import { useActiveElement, useEventListener } from '@vueuse/core'

const activeElement = useActiveElement()
const usingInput = computed(() => {
  if (['INPUT', 'TEXTAREA', 'SELECT'].includes(activeElement.value?.tagName ?? '')) {
    return true
  }

  if (activeElement.value?.isContentEditable === true) {
    return true
  }

  // Headless UI Dropdowns
  if (activeElement.value?.dataset.headlessuiState === 'open') {
    return true
  }

  return false
})

export type KeyModifier = 'shift' | 'ctrl' | 'alt'
export type Key =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | 'space'
  | 'enter'
  | 'escape'
  | 'backspace'
  | 'delete'
  | 'ArrowUp'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'home'
  | 'end'
  | 'pageup'
  | 'pagedown'
  | 'f1'
  | 'f2'
  | 'f3'
  | 'f4'
  | 'f5'
  | 'f6'
  | 'f7'
  | 'f8'
  | 'f9'
  | 'f10'
  | 'f11'
  | 'f12'
  | 'plus'
  | 'minus'
  | 'multiply'
  | 'divide'
  | 'equal'
  | 'comma'
  | 'period'
  | 'semicolon'
  | 'quote'
  | 'backquote'
  | 'bracketleft'
  | 'bracketright'
  | 'backslash'
  | 'slash'

export type KeyCombination = Key | `${KeyModifier}+${Key}`

export function useHotKey(
  keyCombination: KeyCombination,
  handler: (keyboardEvent: KeyboardEvent) => void,
) {
  const keys = keyCombination.split('+').map((k) => k.trim().toLowerCase())

  const shift = keys.includes('shift')
  const ctrl = keys.includes('ctrl')
  const alt = keys.includes('alt')

  const possibleHotKeys = keys.filter((k) => !['shift', 'ctrl', 'alt'].includes(k))
  if (possibleHotKeys.length !== 1) {
    throw new Error('Invalid hotkey')
  }

  const hotKey = possibleHotKeys[0]

  useEventListener('keydown', (event: KeyboardEvent) => {
    // Apparently sometimes the event.key is null or undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (event.key == null) {
      return
    }

    if (event.key.toLowerCase() !== hotKey) {
      return
    }

    if (usingInput.value) {
      return
    }

    if (!matchesModifiers(shift, ctrl, alt, event)) {
      return
    }

    // if we get here, we know the key and modifiers are correct
    handler(event)
  })
}

export type HotKeys = Partial<Record<KeyCombination, (callback: KeyboardEvent) => void>>

export function useHotKeys(hotKeys: HotKeys) {
  for (const [keyCombination, handler] of Object.entries(hotKeys)) {
    useHotKey(keyCombination as KeyCombination, handler as (event: KeyboardEvent) => void)
  }
}

function matchesModifiers(shift: boolean, ctrl: boolean, alt: boolean, event: KeyboardEvent) {
  if (shift && !event.shiftKey) {
    return false
  }

  if (ctrl && !event.ctrlKey && !event.metaKey) {
    return false
  }

  if (alt && !event.altKey) {
    return false
  }

  if (!shift && event.shiftKey) {
    return false
  }

  if (!ctrl && (event.ctrlKey || event.metaKey)) {
    return false
  }

  if (!alt && event.altKey) {
    return false
  }

  return true
}
