<script lang="ts" setup>
import { breakpointsTailwind } from '@vueuse/core'

import type SidebarVue from './Sidebar.vue'
import type { NavigationItem } from '@/Types'

defineProps<{
  items: NavigationItem[]
}>()

const sidebarOpen = useStorage('sidebarOpen', true)
const sidebarFixed = useStorage('sidebarFixed', true)

useHotKeys({
  'shift+x': () => {
    sidebarFixed.value ? unfixSidebar() : fixSidebar()
  },
  'shift+c': () => {
    sidebarOpen.value ? closeSidebarImmediately() : openSidebarImmediately()
  },
})

const isMobile = useBreakpoints(breakpointsTailwind).smallerOrEqual('sm')
watchEffect(() => {
  if (!isMobile.value) return
  sidebarOpen.value = false
  sidebarFixed.value = false
})

const sidebar = ref<InstanceType<typeof SidebarVue>>()
onClickOutside(sidebar, closeSidebarImmediately)

router.on('navigate', () => {
  if (!isMobile.value) return
  closeSidebarImmediately()
})

const { start: registerHover, stop: cancelHover } = useTimeoutFn(
  () => {
    sidebarOpen.value = true
  },
  300,
  { immediate: false },
)

const { start: registerUnhover, stop: cancelUnhover } = useTimeoutFn(
  () => {
    sidebarOpen.value = false
  },
  300,
  { immediate: false },
)

function closeSidebarImmediately() {
  if (sidebarFixed.value) return
  cancelHover()
  sidebarOpen.value = false
}

function closeSidebarAfterDelay() {
  if (sidebarFixed.value) return
  registerUnhover()
  cancelHover()
}

function openSidebarAfterDelay() {
  if (sidebarFixed.value) return
  registerHover()
  cancelUnhover()
}

function openSidebarImmediately() {
  if (sidebarFixed.value) return
  cancelUnhover()
  sidebarOpen.value = true
}

function fixSidebar() {
  if (isMobile.value) return
  sidebarFixed.value = true
  sidebarOpen.value = true
}

function unfixSidebar() {
  if (isMobile.value) return
  sidebarFixed.value = false
  sidebarOpen.value = false
}
</script>

<template>
  <div class="relative h-full">
    <div
      class="fixed z-20 h-screen transition-all"
      :class="[
        sidebarOpen ? 'translate-x-0' : 'translate-x-[-16rem]',
        !sidebarFixed && !isMobile ? 'px-2 py-4' : '',
      ]"
      @mouseenter="openSidebarAfterDelay"
      @mouseleave="closeSidebarAfterDelay"
    >
      <Sidebar
        ref="sidebar"
        :items="items"
        :class="{
          'overflow-hidden shadow-xl': !sidebarFixed,
          rounded: !sidebarFixed && !isMobile,
        }"
      />

      <div
        v-if="sidebarFixed && !isMobile"
        class="group absolute inset-y-0 -right-1 z-40 w-2 cursor-w-resize"
        title="Navigation einklappen (⇧ + X)"
        @click="unfixSidebar"
      >
        <div class="absolute inset-y-0 left-1 w-0.5 bg-gray-100 group-hover:bg-gray-500" />
      </div>

      <div
        v-if="!sidebarFixed && !isMobile"
        class="absolute bottom-4 right-0 z-50 translate-x-12 transition-all"
      >
        <TuiTooltip text="Navigation ausklappen (⇧ + X)">
          <TuiIconButton class="bg-primary-200 shadow-lg" @click="fixSidebar">
            <SidebarIcon />
          </TuiIconButton>
        </TuiTooltip>
      </div>

      <div
        v-else-if="isMobile && !sidebarOpen"
        class="absolute bottom-10 right-0 z-50 translate-x-12 transition-all ease-out"
      >
        <TuiIconButton class="bg-primary-200 shadow-lg" @click="openSidebarImmediately">
          <SidebarIcon />
        </TuiIconButton>
      </div>
    </div>
  </div>

  <div class="h-0 transition-all" :class="[sidebarFixed ? 'w-64' : 'w-0']" />
</template>
