<script setup lang="ts">
const navigation = useNavigation()

const showNotification = ref(false)

const flash = useFlash()
watchEffect(() => {
  if (flash.value?.banner) {
    showNotification.value = true
  }
})
</script>

<template>
  <div class="flex h-full">
    <div class="flex-none">
      <SidebarNavigation :items="navigation!" />
    </div>

    <main class="isolate min-w-0 grow overflow-y-auto">
      <slot />
    </main>

    <TuiNotificationHost alignment="top-right" class="z-20" />

    <TuiFlashNotification
      v-if="flash"
      v-model:show="showNotification"
      class="z-20"
      :title="flash.banner"
      :type="flash.bannerStyle"
    />
  </div>
</template>
